import React, {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Button, Grid} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Paths} from "../../router/routes";
import {getPrograms} from "./programActions";
import {useSnackbar} from "notistack";
import {SocketContext} from "../../context/ConnectionProvider";


const ProgramsPage = () => {
    const dispatch = useDispatch();
    const groups = useSelector(({programs}) => programs.interface);
    const projectUID = useSelector(({app}) => app.projectUID);
    const connection = useContext(SocketContext);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    if(groups.length === 1){
        history.push(`${Paths.Programs}/${groups[0]._id}`)
    }

    useEffect(() => {
        if (connection.socket)
            connection.socket.emit(
                "getPrograms",
                {projectUID},
                ({status, message, data}) => {
                    status === "success" ? dispatch(getPrograms(data)) : enqueueSnackbar(message, {variant: status})
                }
            )
    }, [connection.socket]);

    const handleSelectGroup = (_id) => {
        history.push(`${Paths.Programs}/${_id}`)
    }

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            {groups.map(group => (
                <Grid  item key={group._id} xs={12} ms={12} md={3} lg={2} xl={2}>
                    <Button
                        onClick={() => handleSelectGroup(group._id)}
                        variant={"outlined"}
                        fullWidth
                        size="large"
                        style={{height:"7vh", minHeight:60}}
                    >
                        {group.name}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};

export {ProgramsPage};
