import React, {useCallback, useContext, useEffect} from 'react';
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";

import {useDispatch, useSelector} from "react-redux";
import {toggleSideMenu} from "../../store/appActions";
import {getPrograms, runProgram} from "./programActions";
import {SocketContext} from "../../context/ConnectionProvider";
import {Paths} from "../../router/routes";
import {useTranslation} from "react-i18next";

const ProgramPage = () => {
    const params = useParams();
    const projectUID = useSelector(({app}) => app.projectUID);
    const programs = useSelector(({programs}) => programs.interface);
    const group = useSelector(({programs}) => programs.interface.find(group => group._id === params.groupID));
    const connection = useContext(SocketContext);
    const isAutoMode = useSelector(({app}) => app.isAutoMode);
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const snackbarAction = useCallback((key) => (
        <Button onClick={() => {
            dispatch(toggleSideMenu());
            closeSnackbar(key)
        }}>
            {t("message.on")}
        </Button>
    ), []);

    const handleRunProgram = (groupID, programID) => {
        if (!isAutoMode) {
            enqueueSnackbar(t("message.manualMode"), {
                variant: 'warning',
                action: snackbarAction
            });
            return
        }
        connection.socket.emit("runProgram", {projectUID, groupID, programID}, ({status, message}) => {
            if(message === "Программа запущена успешно."){
                message = t("message.successRun")
            }
            if(message === "connected"){
                message = t("message.connected")
            }
            if(message === "await"){
                message = t("message.wait")
            }
            if(message.replace("не подключён",t("notConn")))
            enqueueSnackbar(message, {variant: status})
        })
    }

    const changeActiveProgram = (groupID, programID) => {
        const newPrograms = JSON.parse(JSON.stringify(programs)).map(group => {
            if (group._id === groupID) return {...group, activeProgram: programID}
            return group
        });
        dispatch(getPrograms(newPrograms))
    }

    useEffect(() => {
        connection.socket.on("activeProgramChange", changeActiveProgram);
        return () => {
            connection.socket.off("activeProgramChange", changeActiveProgram);
        }
    }, [changeActiveProgram])

    if (!group) return <React.Fragment>{history.push(Paths.Programs)}</React.Fragment>

    return (
        <React.Fragment>
            <Box mb={2}>
                <Typography variant={"h4"} color={"primary"} align={'center'}>
                    {group.name}
                </Typography>
            </Box>

            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {group.programs.map(program => (
                    <Grid item key={program._id} xs={6} ms={6} md={3} lg={2} xl={2}>
                        <Button variant={"outlined"}
                                fullWidth
                                size="large"
                                style={
                                    group.activeProgram === program._id ?
                                        {
                                            height: "7vh",
                                            minHeight: 60,
                                            backgroundColor: program.color,
                                            border: `2px solid ${program.borderColor}`,
                                            boxShadow: `0px 0px 15px 3px ${program.borderColor}`,
                                        } :
                                        {
                                            height: "7vh",
                                            minHeight: 60,
                                            backgroundColor: program.color,
                                            border: `2px solid ${program.borderColor}`
                                        }
                                }
                                onClick={() => {
                                    handleRunProgram(group._id, program._id)
                                }}
                        >
                            {program.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export {ProgramPage}
