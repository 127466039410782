import React, { useCallback } from "react";
import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { logout, toggleSideMenu } from "../store/appActions";
import { SetAutoModeSwitcher } from "./options/SetAutoModeSwitcher";
import LanguageButton from "./LangugeButton";
import TranslateIcon from "@material-ui/icons/Translate";
import { useTranslation } from "react-i18next";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
const SideMenu = () => {
  const { t } = useTranslation();
  const openMenu = useSelector(({ app }) => app.openMenu);
  const dispatch = useDispatch();
  const menuBackground = useSelector(({ theme }) => theme.menuBackground);
  const logoutFunc = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  return (
    <Drawer
      anchor="right"
      open={openMenu}
      onClose={() => dispatch(toggleSideMenu())}
    >
      <List
        style={{
          paddingTop: "env(safe-area-inset-top, 0)",
          backgroundColor: menuBackground,
          height: "100%",
        }}
        subheader={<ListSubheader>{t("settings.header")}</ListSubheader>}
      >
        <Divider />
        <ListItem>
          <ListItemIcon>
            <SetAutoModeSwitcher />
          </ListItemIcon>
          <ListItemText primary={t("settings.mode")} />
        </ListItem>
        <LanguageButton />
      </List>
      <Divider />
      <Box sx={{pb: "calc(env(safe-area-inset-bottom, 0) / 2)"}}>
        <Button fullWidth startIcon={<ExitToAppIcon />} onClick={logoutFunc}>
          {t("exit")}
        </Button>
        <Divider />
      </Box>

    </Drawer>
  );
};

export default SideMenu;
