import React, { useEffect,} from 'react';
import {Routes} from "./router/routes";
import {useSelector} from "react-redux";
import {BottomNav} from "./coomponents/BottomNav";
import {TopNav} from "./coomponents/TopNav";
import SideMenu from "./coomponents/SideMenu";
import {
    Grow,
    useTheme,
    makeStyles
} from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import {ConnectionProvider} from "./context/ConnectionProvider";
import {useTranslation, withTranslation} from "react-i18next";

const useStyle = makeStyles((theme) => {
    return {
        snackContainer: {
            bottom: `calc(${theme.mixins.toolbar.minHeight + 34}px + env(safe-area-inset-bottom, 0))`,
        }
    }
})


function App() {
    const isAuth = useSelector(({app}) => app.isAuth);
    const classes = useStyle();
    const them = useTheme();
    useEffect(() => {
        fetch(`/api/user/init`).then(res => {
            console.log(res)
        }).catch(console.error)
    }, [])

    useEffect(() => {
        const themeColor = document.querySelector('[name="theme-color"]');
        isAuth ? themeColor.content = them.palette.background.paper : themeColor.content = them.palette.background.default;
    }, [isAuth, them]);
    return (
        <SnackbarProvider
            dense
            maxSnack={1}
            classes={{containerAnchorOriginBottomCenter: classes.snackContainer}}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            TransitionComponent={Grow}
        >
            <ConnectionProvider>
                {isAuth && <TopNav/>}
                <Routes/>
                <SideMenu/>
                {isAuth && <BottomNav/>}
            </ConnectionProvider>
            {/*<Dialog fullWidth maxWidth={"md"} open={open}>*/}
            {/*    <DialogTitle>*/}
            {/*        Доступна новая версия!*/}
            {/*    </DialogTitle>*/}
            {/*    <DialogContent dividers>*/}
            {/*        <DialogContentText>*/}
            {/*            Обновление установлено и будет доступно после рестарта приложения.*/}
            {/*        </DialogContentText>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={acceptUpdateHandler}>Принять</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </SnackbarProvider>
    );
}

export default App;
