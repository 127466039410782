import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideMenu } from "../store/appActions";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  root: {
    // backgroundColor:theme.palette.background.paper,
    paddingTop: "env(safe-area-inset-top, 0)",
  },

  title: {
    flexGrow: 1,
    textAlign: "center",
    "& > img": {
      maxHeight: theme.mixins.toolbar.minHeight,
      maxWidth: "60vw",
    },
  },
}));

const TopNav = () => {
  const classes = useStyle();

  const dispatch = useDispatch();
  const logo = useSelector(({ theme }) => theme.logo);
  const name = useSelector(({ theme }) => theme.name);
  const menuBackground = useSelector(({ theme }) => theme.menuBackground);
  const history = useHistory();
  const location = useLocation();
  const handleBack = () => {
    history.goBack();
  };

  return (
    <AppBar>
      <Toolbar
        className={classes.root}
        style={{ backgroundColor: menuBackground }}
      >
        <IconButton
          disabled={
            location.pathname === "/programs" ||
            location.pathname === "/calendar"
          }
          onClick={() => handleBack()}
          edge="start"
          aria-label="menu"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Box className={classes.title}>
          {logo ? (
            <img
              className={classes.img}
              src={`/static/logo/${logo}`}
              alt="logo"
            />
          ) : (
            <Typography variant={"h5"} color={"primary"}>
              {name}
            </Typography>
          )}
        </Box>
        <IconButton
          edge="end"
          onClick={() => dispatch(toggleSideMenu())}
          aria-label="menu"
        >
          <SettingsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export { TopNav };
