import React, {useEffect, useRef, useState} from "react";
import {Backdrop, CircularProgress, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useSelector} from "react-redux";
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyle = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.default,
            position: "relative",
            overscrollBehaviorY: "none",
            overflowX: "hidden",
            minHeight: "100vh",
            paddingTop: `calc(${theme.mixins.toolbar.minHeight * 1.20}px + env(safe-area-inset-top, 0))`,
            paddingBottom: `calc(${theme.mixins.toolbar.minHeight + 52}px + env(safe-area-inset-bottom, 0))`,
            "@media(min-width:768px)": {
                paddingTop: theme.mixins.toolbar.minHeight * 1.50,
                paddingBottom: 108
            }
        }
    })
)

const MainView = ({children}) => {
    const classes = useStyle()
    const background = useSelector(({theme}) => theme.background);
    return (
        <Container style={{backgroundColor: background}}
                   maxWidth="xl"
                   className={classes.root}
        >
            {children}
        </Container>
    )
}

export {MainView}
